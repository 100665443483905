<template>
  <!-- badge -->
  <div>
    <div v-if="loading" class="ml-2 mr-1 mt-1">
      <b-spinner variant="primary" label="Loading..." class="mr-1" style="font-size: 18px; vertical-align: middle; position: flex"/>
      <span style="font-size: 18px; vertical-align: middle; position: flex">Loading Stream Stats</span>
    </div>  
  <b-list-group v-if="!loading">
    <b-list-group-item class="d-flex justify-content-left align-items-center" v-if="channelData">
      <img src="https://assets.brimecdn.com/brime_logo_tailwind.png" width="20">
      <b-badge
        v-if="channelData.stream.live"
        variant="primary"
        style="background: red; color: #eeeeee;"
        class="ml-1"
        pill
      > LIVE
      </b-badge>
      <b-badge
        v-if="!channelData.stream.live"
        variant="primary"
        style="background: grey; color: #eeeeee;"
        class="ml-1"
        pill
      > OFFLINE
      </b-badge>
      <b-badge
      v-if="channelData.stream"
      class="ml-1 fixed-pill"
        variant="primary"
        pill
      ><feather-icon
      icon="EyeIcon"
      class="mr-50"
    />
      <span> {{ viewers }}</span>
      </b-badge>
      <b-badge
      v-if="channelData.stream"
      class="ml-1 fixed-pill"
        variant="secondary"
        pill
      >
      <feather-icon
      icon="HeartIcon"
      class="mr-50"
    />
      <span>{{ numberWithCommas(followers) }} </span>
      </b-badge>
    </b-list-group-item>
    <span v-if="multistream.length > 0">
    <b-list-group-item class="d-flex justify-content-left align-items-center" v-for="endpoint in orderBy(multistream,'platform', -1)" :key="endpoint.platform">
      <img v-if="endpoint.platform == 'twitch'" src="https://assets.brimecdn.com/twitch_icon.png" width="20">
      <img v-if="endpoint.platform == 'trovo'" src="https://assets.brimecdn.com/trovo_icon.png" width="20">
      <img v-if="endpoint.platform == 'youtube'" src="https://assets.brimecdn.com/youtube-icon.svg" width="22">
      <b-badge
        v-if="endpoint.live"
        variant="primary"
        style="background: red; color: #eeeeee;"
        class="ml-1"
        pill
      > LIVE
      </b-badge>
      <b-badge
        v-if="!endpoint.live"
        variant="primary"
        style="background: grey; color: #eeeeee;"
        class="ml-1"
        pill
      > OFFLINE
      </b-badge>
      <b-badge
      v-if="endpoint"
      class="ml-1 fixed-pill"
        variant="primary"
        pill
      >
      <feather-icon
      icon="EyeIcon"
      class="mr-50"
    />
      {{ endpoint.viewers }} 
      </b-badge>
      <b-badge
      v-if="endpoint"
      :title="`${endpoint.platform} followers`"
      class="ml-1 fixed-pill"
        variant="secondary"
        pill
      >
      <feather-icon
      icon="HeartIcon"
      class="mr-50"
    />
      <span>{{ numberWithCommas(endpoint.followers) }} </span>
        <!-- {{(endpoint.followers > 1 ? 'followers' : 'follower')}} -->
      </b-badge>
    </b-list-group-item>
  </span>
  </b-list-group>
</div>
</template>

<script>
import { BListGroup, BListGroupItem, BBadge, BSpinner } from 'bootstrap-vue'
import Vue2Filters from 'vue2-filters'

export default {
  mixins: [Vue2Filters.mixin],
  components: {
    BBadge,
    BListGroup,
    BListGroupItem,
    BSpinner
  },
  methods: {
    numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  },
  data() {
    return {
      loading: true,
      channelData: null,
      viewers: null,
      followers: 0,
      subscribers: 0,
      countTerm: 'viewer',
      multistream: [],
      multistreamViewers: {},
    }
  },
  async mounted() {
    const slug = window.location.pathname.substring(1).replace('/stats', '')
    this.channelData = await this.channelLookupBySlug(slug)
    document.title = this.channelData.display_name + ' Live Stats - Brime'
    this.viewers = await this.getViewercount(this.channelData.xid)
    this.multistream = await this.multistreamStats(this.channelData.xid)
      this.channelData = await this.channelLookupBySlug(slug)
      this.followers = this.channelData.followers

      this.viewCounter = setInterval(async () => {
      this.multistream = await this.multistreamStats(this.channelData.xid)
      this.channelData = await this.channelLookupBySlug(slug)
      this.viewers = await this.getViewercount(this.channelData.xid)
      this.countTerm = (this.viewerCount > 1 ? 'viewers' : 'viewer')
    }, 5000)
  },
  watch:{
    multistream(a, b){
      this.loading = false
    }
  }
}
</script>
<style>
  .feather{
    margin-right: 5px !important;
  }
  .fixed-pill {
    padding-left: 8px;
    padding-right: 8px;
    text-align: left;
  }
</style>
